export function preloaderFinished() {
  // tslint:disable-next-line: no-non-null-assertion
  const body = document.querySelector('body')!;
  // tslint:disable-next-line: no-non-null-assertion
  const preloader = document.querySelector('.preloader')!;

  body.style.overflow = 'hidden';

  function remove() {
    // preloader value null when running --hmr
    if (!preloader) { return; }
    preloader.addEventListener('transitionend', () => {
      preloader.className = 'preloader-hidden';
    });

    preloader.className += ' preloader-hidden-add preloader-hidden-add-active';
  }

  (window as any).appBootstrap = () => {
    setTimeout(() => {
      remove();
      body.style.overflow = '';
    }, 100);
  };
}
