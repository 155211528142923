import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layout/default/default.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    children: [
      { path: 'client', loadChildren: () => import('./pages/client/client.module').then(m => m.ClientModule) },
      { path: 'interface', loadChildren: () => import('./pages/interface/interface.module').then(m => m.InterfaceModule) },
      { path: 'cmc-res', loadChildren: () => import('./pages/cmc-res/cmc-res.module').then(m => m.CmcResModule) },
      { path: 'pos-res', loadChildren: () => import('./pages/pos-res/pos-res.module').then(m => m.PosResModule) },
      { path: 'sma-res', loadChildren: () => import('./pages/sma-res/sma-res.module').then(m => m.SmaResModule) },
      { path: 'brand', loadChildren: () => import('./pages/brand/brand.module').then(m => m.BrandModule) },
      { path: 'pay-config', loadChildren: () => import('./pages/pay-config/pay-config.module').then(m => m.PayConfigModule) },
      { path: '', redirectTo: '/cmc-res', pathMatch: 'full' },
    ]
  },
  { path: '**', redirectTo: '/cmc-res' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
