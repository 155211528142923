<nz-layout class="app-layout">
  <!-- <nz-sider class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null">
    <div class="sidebar-logo">
      <a href="https://ng.ant.design/"
        target="_blank">
        <img src="/assets/logo.png"
          alt="logo">
        <h1>智企道.客服系统</h1>
      </a>
    </div>
    <ul nz-menu
      nzTheme="dark"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed">
      <li nz-submenu
        nzOpen
        nzTitle="Dashboard"
        nzIcon="dashboard">
        <ul>
          <li nz-menu-item
            nzMatchRouter>
            <a routerLink="/welcome">Welcome</a>
          </li>
          <li nz-menu-item
            nzMatchRouter>
            <a>Monitor</a>
          </li>
          <li nz-menu-item
            nzMatchRouter>
            <a>Workplace</a>
          </li>
        </ul>
      </li>
      <li nz-submenu
        nzOpen
        nzTitle="Form"
        nzIcon="form">
        <ul>
          <li nz-menu-item
            nzMatchRouter>
            <a>Basic Form</a>
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider> -->
  <nz-layout>
    <nz-header>
      <!-- <div class="app-header">
        <span class="header-trigger"
          (click)="isCollapsed = !isCollapsed">
          <i class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
        </span>
      </div> -->
      <div class="d-flex">
        <div class="sidebar-logo">
          <a href="https://ng.ant.design/"
             target="_blank">
            <img src="/assets/logo.png"
                 alt="logo">
            <h1>智企道.客服系统</h1>
          </a>
        </div>
        <ul nz-menu
            nzTheme="dark"
            nzMode="horizontal"
            class="ml-lg">
          <li nz-submenu
              nzTitle="产品资源">
            <ul>
              <li nz-menu-item
                  [nzMatchRouter]="true"
                  routerLink="/cmc-res">CMC产品资源</li>
              <li nz-menu-item
                  [nzMatchRouter]="true"
                  routerLink="/pos-res">POS产品资源</li>
              <li nz-menu-item
                  [nzMatchRouter]="true"
                  routerLink="/sma-res">门店助手产品资源</li>
            </ul>
          </li>
          <li nz-menu-item
              [nzMatchRouter]="true"
              routerLink="/client">商户</li>
          <li nz-menu-item
              [nzMatchRouter]="true"
              routerLink="/interface">接口地址</li>
          <li nz-menu-item
              [nzMatchRouter]="true"
              routerLink="/brand">品牌区域</li>
          <li nz-menu-item
              [nzMatchRouter]="true"
              routerLink="/pay-config">聚合支付</li>
        </ul>
      </div>

    </nz-header>
    <nz-content class="mx-md my-md">
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>